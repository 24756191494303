html {
  color: $black;
  line-height: 1.4;

  font-weight: 400;
  font-size: 15px; //1.0em
  font-family: $font-2;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 $space-3;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  text-rendering: optimizeLegibility;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  line-height: 1.2;
  // font-family: $font-1;
}
h1,
.h1 {
  // font-size: 80px;
  font-size: 56px;
  line-height: 1.1;
}

h2,
.h2 {
  font-size: 32px;
  font-weight: 300;
}

h3,
.h3 {
  // line-height: 1;
  font-size: 20px;
  font-weight: 400;
}

h4,
.h4 {
  font-size: 18px;
}

@media (max-width: $sm) {
  h3,
  .h3 {
    font-size: 24px;
  }
}

.list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}

a {
  color: $primary;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
