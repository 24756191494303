/**
 * links
 */
.link {
  text-decoration: none;
  transition: color 150ms;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.link--default {
  color: inherit;
}

.link--primary {
  color: $primary;

  &:hover,
  &:focus {
    color: $primary-dark;
  }
}

.link--black {
  color: $black;
}

.link--withIcon {
  .icon {
    margin-left: $space-1;
    font-size: 1.65em;
  }
}

/**
 * hamburger
 */
.hamburger {
  display: inline-block;
  vertical-align: middle;
}

.hamburger-bar {
  display: block;
  width: 26px;
  height: 4px;
  border-radius: 3px;
  background-color: currentColor;
  transform-origin: center;
  transition: all 250ms;

  &:not(:first-child) {
    margin-top: 5px;
  }
}

// .hamburger-bar:nth-child(1) {
// 	transform-origin: center;
// }
// .hamburger-bar:nth-child(3) {
// 	transform-origin: center;
// }

.is-active,
.is-offCanvasActive {
  .hamburger-bar:nth-child(1) {
    transform: translateY(9px) rotate(-45deg);
  }

  .hamburger-bar:nth-child(2) {
    transform: scaleX(0);
  }

  .hamburger-bar:nth-child(3) {
    transform: translateY(-9px) rotate(45deg);
  }
}

/**
 * Logo
 */
.logo {
  display: inline-block;
  margin: 0;
  font-family: $font-1;
  line-height: 1;

  a {
    text-decoration: none;
  }
}

.logo-prefix {
  color: $primary;
  font-weight: 700;
}

.logo-suffix {
  color: $black;
  font-weight: 100;
}

.logo--sm {
  font-size: 26px;
}

.logo--md {
  font-size: 38px;
}

.logo--lg {
  font-size: 56px;
}

.logo--light {
  .logo-prefix,
  .logo-suffix {
    color: $white;
  }
}

.referendum{
  h1{
    color: #0B4EA2;
  }
  h2{
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    width: 100%;
    margin: 40px 0 22px;
  }
  .info-box{
    padding: 12px 16px;
    background: #efefef;
    margin: 10px 0 15px;
    display: inline-block;
  }
  ul{
    list-style: none;
    margin-bottom: 60px;
  }
}

.chart-row{
  display: flex;
  margin: 50px 0 0;
  p{
    width: 100%;
    text-align: center;
    color: #6c6c6c;
    font-style: italic;
    margin-top: -10px;
    margin-bottom: 60px;
  }
}
.chart-col{
  width: 50%;
  box-sizing: border-box;
  flex-grow: 1;
  &.full{
    width: 100%;
    .chart-hdl{
      text-align: left;
    }
  }
}

.chart-hdl{
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.chart-box.parties.mobile{
  display: none;
}
.chart-box{
  &.parties{
    // padding-left: 200px;
  }
  &.pie{
    height: 300px;
  }
  &.line{
    height: 140px;

    &.thin{
      height: 90px;
    }
  }
  &.parties{
    height: 500px;
  }
  &.eu{
    height: 700px;
  }
}
.text-center{
  text-align: center;
}
.mb-20{
  margin-bottom: 20px;
}
.mt-20{
  margin-top: 20px;
}
.data-table-filters{
  display: flex;
  padding: 20px 0;
  > *{
    &:not(:last-child){
      margin-right: 15px;
    }
  }
}
.temp-text{
  display: block;
  max-width: 600px;
  margin: 20px auto;  
  p{
    padding: 20px;
    border: 1px solid #e6e6e6;
    font-style: normal;
  }  
}

.formMessage{
  padding: 6px 0 10px;
  &.error{
    color: #cf383f;
  }
}

.ass-page-wrapper{
  background: #F3F6FA;
  padding: 25px 26px;
  border-radius: 2px;
  border: .5px solid #BCD6F1;
  width: 100%;
  max-width: 1100px;
  margin: 0px auto 0;
}


.col-header{
  display: flex;
  gap: 10px;  
}
.col-ass{
  display: flex;
  flex-direction: column;
  .ass-banner-list{
    flex-grow: 1;
  }
  .top-col{
    display: flex;
    align-items: center;
    // width: 50%;    
    .desc{
      font-size: 13px;
      color: #616161;
    }
    .icon{
      margin-right: 15px;
    }
    .title{
      color: #24568A;
      font-weight: bold;
      font-size: 18px;
    }
  }
}
.row-ass{
  display: flex;
  gap: 10px;
}
.col-body{
  display: flex;
  gap: 10px;
}

.ass-2-cols{
  .row-ass{
    .col-ass{
      width: 50%;
      .top-col{
        min-height: 45px;
      }      
    }
  }
  .col-header{
    // .top-col{
    //   width: 50%;
    // }
  }
  .col-body{
    // .ass-banner-list{
    //   width: 50%;
    // }
  }
}

.ass-4-cols{
  .row-ass{
    .col-ass{
      width: 25%;
      .top-col{
        // min-height: 45px;
      }      
    }
  }
}

.static-page{
  &.ass{
    h1{
      margin: 40px 0 50px;
      color: #24568A;
      font-size: 38px;
    }
    a{
      @media(hover: hover){
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}

.embedded-post-popup{
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: rgba(255,255,255, .7);
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  .close-popup{
    background:#24568A;
    color: #FFF;
    text-transform: uppercase;
    padding: 3px 12px;
    margin-bottom: 7px;
    display: inline-block;
    font-weight: 500;
    cursor: pointer;
    border-radius: 2px;
  }
  .inner{
    text-align: right;
    width: 550px;
    height: auto;
    padding: 25px;
    // background: #FFF;    
  }
  // top: 120px;
  // left: 50%;
  // margin-left: -300px;  
  
}
 
.ass-banner-list{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
  > *{
    flex-grow: 1;
    display: block;
  }
  &.bg{
    padding-top: 10px;
    padding-bottom: 20px;
    background: #FFF;
    border: .5px solid #BCD6F1;
    gap: 0;
    .ass-banner-item{
      background: transparent;
      border: 0;
      padding: 10px 16px 0;
    }
  }
}


.ass-banner-item{
  background: #FFF;
  border: .5px solid #BCD6F1;
  border-radius: 2px;
  padding: 14px 16px;
  display: flex;
  align-items: center;
  position: relative;
  gap: 15px;
  width: 100%;
  @media(hover: hover){
    &:hover{
      cursor: pointer;
      background: #f1f7fd;
    }
  }
  .text{
    flex-grow: 1;
  }
  &.yt{
    &:after{
      position: absolute;
      bottom: 12px;
      right: 12px;
      width: 14px;
      height: 12px;
      opacity: .3;
      background: url("../../img/icon-yt.svg") no-repeat;  
      background-size: contain;
      content: '';
    }
    .text{
      .meta{
        font-size: 12px;
        color: #5D5D5D;
        margin-top: 3px;
      }
    }
  }
  
  &.sm{
    gap: 10px;
    .img-wrapper{
      width: 52px;
      min-width: 52px;
      height: 52px;
      &.circle{
        border-radius: 100%;
        min-width: 40px;
        width: 40px;
        height: 40px;
      }
    }
    .profile-name{
      font-size: 10px;
      color: #5D5D5D;
      margin-bottom: 1px;
    }
    .title{
      font-size: 12px;
      max-width: 90%;
    }
  }
  .img-wrapper{
    width: 100px;
    min-width: 100px;
    height: 58px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .title{
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 3px;
    color: #000;
    max-width: 85%;
    &.xl{
      font-size: 16px;
      font-weight: bold;
    }
  }
  
}

.col-search-bar{
  margin-top: 35px;
  display: flex;
  align-items: center;
  .title{
    display: flex;
    align-items: center;
    gap: 15px;
    span{
      color: #24568A;
      font-weight: bold;
      font-size: 18px;
    }
  }
  .search-form{
    margin-left: 50px;
    .input-wrapper{
      border: .5px solid #A0BCD8;
      background: #FFF;
      padding: 7px 15px;
      display: flex;
      align-items: center;
      .icon-search {
        width: 17px;
        height: 17px;
        transform: translateY(-2px);
      }
      button{
        border: 0;
        padding: 0;
        background: transparent;
      }
      input{
        border: 0;
        background: transparent;
        font-size: 16px;
        outline: none;
        &::placeholder{
          color: #7C8C9D;
        }
      }
    }
  }
  .filter-bar{
    list-style: none;
    display: flex;
    align-items: center;
    margin-left: 30px;    
    padding-left: 0;
    .filter-toggle{
      cursor: pointer;
      &.active{
        font-weight: bold;
        color:#24568A;
        text-decoration: underline;
      }
    }
    li{
      margin-right: 15px;
      padding-right: 15px;
      font-size: 12px;
      &:not(:last-child){
        border-right: 1px solid #b7b7b7;
      }
    }
  }
}

.ass-search-results-wrapper{
  padding: 22px 20px;
  border: .5px solid #A0BCD8;
  background: #FFF;
  margin-top: 22px;
  > .title{
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .search-results-list{
    // display: flex;
    // justify-content: space-between;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 20px;
    // column-gap: 40px;
    // row-gap: 10px;
    > *{
      width: 100%;
      @media(hover: hover){
        &:hover{
          text-decoration: none;
          .search-result-item{   
            .title{
              text-decoration: none;
            }
            .desc{
              text-decoration: none;
            }
          }
        }
      }
    }
    .search-result-item{      
      // padding: 14px 30px 0 0;
      padding: 8px;
      border: .5px solid #d8d8d8;
      border-radius: 2px;
      display: flex;
      align-items: flex-start;
      position: relative;
      gap: 10px;
      &:after{
        position: absolute;
        bottom: 5px;
        right: 5px;
        width: 15px;
        height: 12px;
        opacity: .5;   
        content: '';        
      }
      &.YOUTUBE{
        &:after{
          background: url("../../img/icon-yt.svg") no-repeat;  
          background-size: contain;
        }        
      }
      &.SPOTIFY{
        &:after{
          background: url("../../img/icon-spotify.svg") no-repeat;  
          background-size: contain;
        }        
      }
      &.TIKTOK{
        &:after{
          background: url("../../img/icon-tiktok.svg") no-repeat;  
          background-size: contain;
        }        
      }
      &.INSTAGRAM{
        &:after{
          background: url("../../img/icon-ig.svg") no-repeat;  
          background-size: contain;
        }        
      }
      &.FACEBOOK{
        &:after{
          background: url("../../img/icon-fb.svg") no-repeat;  
          background-size: contain;
        }        
      }
      .img-wrapper{
        width: 34px;
        min-width: 34px;
        height: 34px;
        background-size: cover;
        background-position: center;
        &.circle{
          border-radius: 100%;
          min-width: 34px;
          width: 34px;
          height: 34px;
        }
      }
      .desc{
        font-size: 11px;
        margin-top: 3px;
        color: #000;
        max-width: 90%;
      }
      .title{
        font-size: 12px;
        max-width: 90%;
        color: #000;
        font-weight: bold;
      }
    }
  }
  
}

.ass-page-socials{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 20px;
  .title{
    font-weight: bold;
    font-size: 18px;
  }
  ul{
    list-style: none;
    display: flex;
    gap: 40px;
    li{
      a{
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: bold;
      }
    }
  }
}

.elections-section{
  padding: 20px 0 80px;
  .custom-select-filter{
    padding: 5px 5px 5px 10px;
    border: 1px solid #9e9e9e;
    display: flex;
    align-items: center;
    position: relative;
    min-width: 170px;
    &.sm{
      min-width: 80px;
    }
    &.party{
      min-width: 230px;
    }
    &::after{
      content: '';
      display: block;
      position: absolute;
      right: 8px;
      top: 50%;
      margin-top: -3px;
      pointer-events: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 13.1l-8-8 2.1-2.2 5.9 5.9 5.9-5.9 2.1 2.2z'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: contain;
      height: 7px;
      width: 8px;
      opacity: .7;
      // margin-left: 10px;
    }
    select{
      border: 0;
      -webkit-appearance: none;
      appearance: none;
      background: #FFF !important;
      color: #666;
      font-size: 90%;
      flex-grow: 1;   
      width: 100%;   
      &:focus{
        outline: none;
      }
    }
  }
  .center-filter{
    display: flex;
    justify-content: center;
    .custom-select-filter{
      display: inline-flex;
    }
  }
}

.data-table-wrapper{  
  .rdt_TableCell{
    > div{
      white-space: break-spaces;
      overflow: initial;
      text-overflow: initial;
    }
  }
  &.summary{
    &.all{
      .rdt_TableHeadRow, .rdt_TableRow{
        > *{
          &:nth-child(1), &:nth-child(2){
            display: none !important;
          }
        }
      }
    }
    &.regions{
      .rdt_TableHeadRow, .rdt_TableRow{
        > *{
          &:nth-child(1), &:nth-child(3){
            display: none !important;
          }
        }
      }
    }
    &.wards{
      .rdt_TableHeadRow, .rdt_TableRow{
        > *{
          &:nth-child(2), &:nth-child(3){
            display: none !important;
          }
        }
      }
    }
    &.districts{
      .rdt_TableHeadRow, .rdt_TableRow{
        > *{
          &:nth-child(2), &:nth-child(3){
            display: none !important;
          }
        }
      }
    }
  }
  .rdt_TableRow{
    > div{
      border-left: 1px solid #f0ede8;
      border-right: 1px solid #f0ede8;
    }
    .rdt_TableCell{
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .rdt_TableHeadRow{
    background: #FBF9F9;    
    font-weight: 700;
    color: #666;
    font-size: 12px;
    > div{
      padding-top: 5px;    
      padding-bottom: 5px;
      border: 1px solid #f0ede8;
      border-bottom: 0;
      padding-left: 8px;
      padding-right: 8px;
    }
    .rdt_TableCol_Sortable{
      > div{
        white-space: wrap !important;
      }
    }
  }  
}

.valued-stats-wrapper{
  display: flex;
  justify-content: center;
  margin-top: 50px;
  .valued-stats-col{
    letter-spacing: 0em;
    text-align: left;
    &.border{
      border-right: 1px solid #BFBFBF;
    }
    &.text-right{
      text-align: right;
    }
    &.red{
      .val{
        color:#EE1C25;
      }
    }        
  }

  .valued-stats-item{
    margin-bottom: 44px;
    padding: 0 45px;
    max-width: 280px;
    .val{
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;      
    }
    .label{
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
    }  
  }
}



@media (max-width: $md) {
  .logo--lg {
    font-size: 64px;
  }
}

@media (max-width: $sm) {
  .chart-box.parties.mobile{
    display: block;
  }
  .chart-box.parties.desktop{
    display: none;
  }
  .elections-section {
    padding: 20px 0 60px;
  }
  
  .static-page.elections h1 {
    font-size: 28px !important;
    line-height: 32px !important;
    margin: 30px auto 30px !important;
  }
  .static-page.elections .counted-graph-wrapper {
    max-width: 480px;
    margin: 30px auto 5px !important;
  }
  .static-page.elections h2 {
    margin: 20px auto 18px;
    font-size: 20px;
    line-height: 24px;
    width: 90%;
}
.elections{
  .chart-col.full {
    width: 100%;
    margin-bottom: 0;
  }
  }
  .chart-box.parties {
    height: 680px;
    margin-top: -50px;
  }
  .elections-results-party{
    b{
      display: block;
    }
  }
  .mobile-center{
    text-align: center;
  }
  .data-table-filters {
    flex-direction: column;
    align-items: flex-start;
}

  .elections-section .custom-select-filter {
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.data-table-filters > *:not(:last-child) {
    margin-right: 15px;
    margin-bottom: 10px;
}
  .valued-stats-wrapper{
    flex-wrap: wrap;
    .valued-stats-col{
      &.border{
        margin-bottom: 30px;
      }
      &:nth-child(1), &:nth-child(2){
        width: 50%;
      }
      .valued-stats-item{
        margin-bottom: 40px;
        padding: 0 25px;
        max-width: 200px;
        .valued-stats-wrapper .valued-stats-item .label {
          font-size: 14px;
          line-height: 19px;
      }
        &:last-child{
          margin-bottom: 10px;
        }
      }
    }
  }
  .chart-box{
    &.pie{
      height: 250px;
      transform: scale(1.3);
    }
    &.line{
      height: 140px;
    }
  }
  .referendum{
    h1{
      font-size: 40px;
    }
    ul{
      margin-bottom: 40px;
      padding-left: 10px;
    }
  }
  .chart-row{
    margin: 0px 0 0;
  }
  .chart-col{
    width: 100%;
    margin-bottom: 40px;
  }
  .chart-row{
    flex-direction: column;
  }
  .logo--lg {
    font-size: 56px;
  }

  .logo--md {
    font-size: 32px;
  }
}

/**
* section
**/

.section--tabletTV {
  background: $beige;
}

.section--vtedy {
  background: $beige-darker;
}

.section--webmagazin,
.section--foto {
  background: $black;
  color: $white;

  a {
    color: $white;
  }
}

.section--foto {
  padding-top: $space-6;
  padding-bottom: $space-5;
}

/**
 * promo
 */
.promo {
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.promo-link {
  display: block;
  width: 100%;
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: none;

    .promo-heading {
      text-decoration: underline;
    }
  }
}

.promo-body {
  overflow: hidden;
}

.promo-heading {
  margin: 10px 10px 5px 0;
  color: $primary;
  font-size: 19px;
  font-family: $font-2;
}

.promo-img {
  z-index: -1;
  max-width: 200px;
  max-height: 100px;
  margin-left: 3px;
  margin-right: 3px;
}

.promo--withImage {
  background-size: contain;
  background-repeat: no-repeat;

  .promo-link {
    padding-right: 100px;
  }
}

.promo--vajansky {
  text-align: right;

  .promo-body {
    display: inline-block;
    text-align: left;
  }

  .promo-img {
    position: absolute;
    right: -8px;

    // bottom: -8px;
  }
}

/**
 * interstitial
 */
.interstitial {
  // padding: $space-5 $space-5 $space-5 0;
  padding-top: $space-5;
  padding-bottom: $space-5;
}

.interstitial-heading {
  margin: 0;
}

.interstitial-icon {
  position: absolute;
  padding: 0 $space-2;
  margin-top: -($space-5 + 15px);
  margin-left: -$space-2;
  background-color: $white;
}

.interstitial-title {
  display: block;
  margin-top: 0.25em;
  font-size: 32px;
  line-height: 1.1;
}

/**
 * like box
 */
.likeBox {
  position: relative;
  display: block;
  padding: $space-4 $space-4 $space-3 180px;
  border: 3px solid #aab7cd;
  border-radius: 6px;
  margin-bottom: $space-4;
  background-color: #edf0f7;
  color: $black;
  font-size: 18px;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.likeBox-icon {
  position: absolute;
  top: 50%;
  left: 35px;
  margin-top: -45px;
}

.likeBox-title {
  margin: 0 0 $space-2;
  color: $primary;
  font-weight: 400;
  font-size: 30px;
}

/**
 * rightNow
 */

.rightNow {
  position: relative;
}

.rightNow-item {
  display: block;

  // padding-right: $space-2;
  font-size: 14px;
}

.rightNow-time {
  font-size: 22px;
}

.rightNow-action {
  visibility: hidden;
  opacity: 0;
  display: inline-block;
  font-size: 30px;
  transition: all 150ms;

  .icon {
    display: block;
  }

  &:not(:first-child) {
    margin-left: $space-1;
  }
}

.rightNow-item:hover .rightNow-action {
  visibility: visible;
  opacity: 1;
}

.rightNow--horizontal {
  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;
    display: block;
    border-bottom: 2px solid $primary;
    margin-right: -$container-padding;
    margin-left: -$container-padding;
  }

  .rightNow-time {
    position: relative;
    display: inline-block;

    &:before {
      content: "";
      display: block;
      width: 2px;
      height: 12px;
      margin: 0 auto $space-1;
      background: $primary;
    }
  }

  .rightNow-main {
    display: table;
    table-layout: fixed;

    // overflow: hidden;
  }

  .rightNow-img,
  .rightNow-body {
    display: table-cell;
    vertical-align: top;
  }

  .rightNow-body {
    // width: 100%;
    // max-width: 250px;
    max-width: 200px;

    // max-width: 250px;
    // overflow: hidden;
  }

  .rightNow-img {
    width: 160px;
    margin-right: $space-2;
  }

  .rightNow-text {
    // width: 250px;
    margin: 0;
  }
}

.rightNow--vertical {
  .rightNow-item {
    font-size: 18px;
  }

  .rightNow-text {
    margin: 0 0 $space-3;
  }
}

.liveBroadcastsVideos {
  padding-top: 12px;
}

.rightNow--topArticles {
  font-size: 14px;
  line-height: 1.5;

  .rightNow--vertical {
    .rightNow-item {
      font-size: 14px;
      margin-bottom: 5px;
    }

    &.trimmed {
      max-height: 386px;
    }
  }
  .rightNow-time {
    font-size: 12px;
  }

  .rightNow {
    overflow: hidden;
    max-height: 80em;
  }
}

.ots-disclaimer{
  display: flex;
  justify-content: flex-end;
  margin: 50px 0 30px;
  color: #484848;
  font-size: 10px;
  > div{
    max-width: 280px !important;
  }
  b{
    font-weight: normal;
  }
}

.topArticles-column {
  margin: $space-3;
  overflow: hidden;
  flex: 1 1;

  &.topArticles-column-main {
    flex-grow: 2;

    .media--lg .media-img {
      padding-bottom: $space-2;
    }
  }

  &.topArticles-column-right {
    flex: 0 0 300px;
  }

  .moduleMain {
    padding-top: 0;
  }
}

/**
 * slider
 */
.slider {
  padding-left: 64px;
  position: relative;

  .owl-prev,
  .owl-next {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 64px;
    padding: 0;
    border: none;
    background: none;
    -webkit-appearance: none;
    color: $primary;
    font-size: 56px;
    outline: none;

    &.disabled {
      // display: none;
      pointer-events: none;

      // opacity: .2;
      color: $gray-lighter;
    }
  }

  .owl-prev {
    // left: -($grid-gutter / 2);
    left: -64px;
    background-image: linear-gradient(
      to right,
      $white $grid-gutter,
      rgba($white, 0)
    );
    text-align: left;
  }

  .owl-next {
    // right: -($grid-gutter / 2);
    right: 0;
    background-image: linear-gradient(
      to left,
      $white $grid-gutter,
      rgba($white, 0)
    );
    text-align: right;
  }
}

.slider-image {
  width: 200px;

  // width: 200px;
  // height: 120px;
}

.slider.slider--foto {
  padding-left: 0;

  .owl-item img {
    transform-style: flat;
  }

  .owl-prev,
  .owl-next {
    color: $white;
    background: none;
    width: 64px;
    font-size: 56px;

    &.disabled {
      color: $gray-darkest;
    }
  }

  .owl-prev {
    left: -64px;
  }

  .owl-next {
    right: -64px;
  }
}

.slider.slider--objektivom {
  .owl-prev,
  .owl-next {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    background: $white;
    color: $primary;

    .icon {
      display: block;
      margin: 0 auto;
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  .owl-prev {
    left: 0;
  }

  .owl-next {
    right: 0;
  }
}

@media (max-width: $md) {
  .slider {
    .owl-prev,
    .owl-next {
      width: 56px;
    }
  }
}

@media (max-width: $sm) {
  .slider {
    padding-left: 40px;

    .owl-prev,
    .owl-next {
      width: 40px;
      font-size: 40px;
    }

    .owl-prev {
      left: -48px;
    }

    .owl-next {
      right: -8px;
    }
  }
}

@media (max-width: $xs) {
  .slider.slider--foto {
    .owl-prev,
    .owl-next {
      color: $black;

      .icon {
        background: $white;
      }

      &.disabled {
        opacity: 0;
      }
    }

    .owl-prev {
      left: -$container-padding / 2;
    }

    .owl-next {
      right: -$container-padding / 2;
    }
  }
}

/**
 * side menu
 */
.sideMenu {
  > li {
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid rgba($white, 0.1);
    }
  }

  > li > a {
    font-weight: 700;
    font-size: 16px;
    padding: $space-2 $space-5 $space-2 $space-4;
  }

  li > a {
    display: block;
    color: inherit;
    text-decoration: none;
    transition: background 150ms;

    &:hover,
    &:focus {
      background: rgba(white, 0.1);

      // .icon {
      // 	transform: translate3d($space-1, 0, 0);
      // }
    }
  }

  > li > ul {
    display: none;
    height: 0;
    overflow: hidden;

    // transform: scaleY(0);
    // transform-origin: top;
    transition: height 500ms;
  }

  > li > ul.is-active {
    display: block;
    height: auto;

    // transform: scaleY(1);
  }
}

.sideMenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 1.4 * 16px + 2 * 8; //line-height * font-size + padding
  padding: 0;
  color: $gray-darker;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  border: none;
  transition: all 150ms ease-in-out;

  &:hover,
  &:focus {
    outline: none;

    // color: $gray;
    background: rgba(white, 0.1);
  }
}

.sideMenu-submenu {
  > li > a {
    padding: $space-1 $space-3 $space-1 $space-5;
  }
}

/**
 * Hot topics
 */
.hotTopics {
  // font-size: 14px;
  font-size: 15px;
  line-height: 1.2;

  li {
    padding-top: $space-3;
    padding-bottom: $space-3;
  }
}

.hotTopics-heading {
  float: left;
  margin-right: $space-3;
}

.hotTopics-nav {
  // line-height: 1;
  overflow: hidden;
}

.hotTopics-nav li {
  float: left;
  margin-right: $space-3;

  // font-size: 20px;
  list-style: none;
}

.hotTopics--flashNews {
  font-size: 21px;
  text-align: center;

  .container {
    background: $yellow;
    padding-top: $space-3;
    padding-bottom: $space-3;
  }

  .hotTopics-heading,
  .hotTopics-nav li {
    float: none;
  }

  .hotTopics-nav {
    display: inline-block;
    overflow: visible;
  }
}

@media (max-width: $sm) {
  .hotTopics-heading {
    float: none;
  }

  // .hotTopics-nav {
  // 	margin-top: $space-1;
  // }
}

/**
 * offcanvas
 */
.page-inner,
.offCanvas {
  transform: translate3d(0, 0, 0);
  transition: all 250ms ease-in-out;

  // will-change: transform;
}

$offCanvas-width: 251px;

.offCanvas {
  position: fixed;
  z-index: 9;
  top: $headerTop-height;
  bottom: -100px;
  z-index: 1000000;

  // left: 0;
  left: -$offCanvas-width;
  width: $offCanvas-width;
  border-right: 1px solid $gray-darkest;
  background: $black;
  color: $white;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  visibility: hidden;
  .sideMenu{
    padding-bottom: 120px;
  }
}

.is-offCanvasActive {
  .offCanvas {
    visibility: visible;
    left: 0px;
  }

  .page-inner,
  .offCanvas {
    //transform: translate3d($offCanvas-width, 0, 0);
  }
}

/**
 * menu
 */
.menu {
  margin-right: -$space-2;
  margin-left: -$space-2;

  > li {
    // float: left;
    display: inline-block;
    margin-right: $space-1;
    margin-left: $space-1;
    font-weight: 700;
    font-size: 16px;

    // &:not(:last-child) {
    // 	margin-right: $space-2;
    // }
    // &:not(:first-child) {
    // 	margin-left: $space-2;
    // }
  }
}

.menu--sm {
  > li {
    font-size: 16px;
  }
}

.menu--tabs {
  // white-space: nowrap;
  // overflow-x: auto;

  > li {
    > a {
      display: block;
      padding: ($space-2 + 4px) $space-1 $space-2;
      border-bottom: 4px solid transparent;
    }
  }

  > li.is-active > a {
    color: $black;
  }

  > li.is-active > a,
  > li > a:hover,
  > li > a:focus {
    border-bottom-color: $gray-lighter;
    text-decoration: none;
  }
}

.submenu {
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    width: $space-4;
    pointer-events: none;
  }

  &:before {
    left: 0;
    background: linear-gradient(-90deg, rgba($white, 0), $white);
  }

  &:after {
    right: 0;
    background: linear-gradient(90deg, rgba($white, 0), $white);
  }
}

.submenu-menu {
  margin: 0 (-$container-padding);
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  > li:first-child {
    margin-left: $container-padding;
  }

  > li:last-child {
    margin-right: $container-padding;
  }
}

// @media (max-width: $md) {
// 	.menu--tabs {
// 		> li {
// 			display: block;
// 		}
// 	}
// }

/**
 * pagination
 */
.pagination {
  color: $primary;
  margin: $space-5 0;
  font-size: 0;

  li {
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
    font-size: 22px;
    line-height: 38px;

    > a {
      display: block;
      padding: 0 12px;
      border: 1px solid transparent;
      text-decoration: none;
      color: inherit;
      transition: all 150ms;

      &:hover,
      &:focus {
        border-color: $gray-lighter;
      }
    }
  }

  li.is-active > a {
    color: $black;
    border-color: $black;
  }

  li.is-disabled {
    color: $gray-lighter;
  }

  .pagination-previous > a,
  .pagination-next > a {
    padding: 0 $space-2;
  }

  .icon {
    display: block;
    font-size: 38px;
  }
}

.section--foto,
.section--webmagazin {
  .pagination {
    color: $gray;

    li.is-active > a {
      color: $white;
      border-color: $white;
    }

    li.is-disabled {
      color: $gray-darkest;
    }
  }
}

/**
 * fadeout
 */
.fadeout {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    // height: 100px;
    height: 2.8em;
    background: linear-gradient(to top, $white, rgba($white, 0));
    pointer-events: none;
  }
}

.section--webmagazin,
.section--foto {
  .fadeout {
    &:after {
      background: linear-gradient(to top, $black, rgba($black, 0));
    }
  }
}

/**
 * Data
 */
.data {
  display: inline-block;
  color: $gray;
  vertical-align: middle;
  text-align: center;
}

.data-value {
  font-weight: 700;
  font-size: 24px;
  line-height: 1;
}

/**
 * socialWidget
 */
.socialWidget {
  display: inline-block;
  height: 32px;
  vertical-align: middle;
  background-color: $gray-lightest;
  line-height: 32px;

  &:not(:last-child) {
    margin-right: $space-1;
  }

  &:hover,
  &:focus {
    .socialWidget-label {
      background-color: $gray-dark;
    }
  }

  .icon {
    margin-top: -2px;
    font-size: 21px;
  }
}

.socialWidget-label,
.socialWidget-value {
  float: left;
  padding: 0 $space-3;
}

.socialWidget-label {
  background-color: $gray;
  color: $white;
  font-weight: 700;
  transition: all 150ms;
}

.socialWidget-value {
  color: $black;
  max-width: 64px;
  display: inline-block;
}


.socialWidget--facebook {
  .socialWidget-label {
    background-color: $facebook;
  }

  &:hover,
  &:focus {
    .socialWidget-label {
      background-color: mix($facebook, black, 85%);
    }
  }
}

.socialWidget--twitter {
  .socialWidget-label {
    background-color: $twitter;
  }

  &:hover,
  &:focus {
    .socialWidget-label {
      background-color: mix($twitter, black, 85%);
    }
  }
}

.socialWidget--comments {
  .socialWidget-label {
    background-color: $primary-lightest;
  }

  &:hover,
  &:focus {
    .socialWidget-label {
      background-color: $primary-light;
    }
  }
}

/**
 * listing
 */
.listing {
}

.listingItem {
  font-weight: 400;

  &:first-child .listingItem-title {
    font-size: 18px;
  }

  &:first-child .listingItem-number,
  &:first-child .listingItem-time {
    padding-top: 2px;
  }

  &:not(:first-child) {
    margin-top: 15px;
  }
}

.listingItem-number {
  float: left;
  width: 2.2857em;
  padding-left: $space-1;
  color: $black;
  font-size: 14px;
}

.listingItem-time {
  float: left;
  width: 2.8em;
  padding-left: $space-1;
  margin-right: $space-3;
  color: $gray;
  font-size: 12px;
  line-height: 1.5;
}

.listingItem-title {
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.5;
  overflow: hidden;
}

.listingItem:first-child .listingItem-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.4;
}

.listingItem:not(:first-child) .listingItem-link {
  color: $primary-dark;
}

.listing--light,
.section--webmagazin,
.section--foto {
  .listingItem-number,
  .listingItem-time,
  .listingItem:not(:first-child) .listingItem-link {
    color: $white;
  }
}

@media (max-width: $md) {
  .listingItem:first-child .listingItem-title {
    font-size: 21px;
  }

  .listingItem-title {
    font-size: 16px;
  }
}

/**
 * banner
 */

.banner {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.banner--horizontal {
  margin: $space-5 auto;
}

/* Import banner css */
/* @import 'banners/vucBanner'; */
// @import "banners/stefanikVyrocie";
// @import "banners/snpVyrocie75";
// @import "banners/ZOH2018";
// @import "banners/obceBanner";
@import "banners/specialBannersBase";
@import "banners/najnovsieSpravyBanner";

/**
 * networks
 */
.networks {
  > li {
    float: left;

    &:not(:last-child) {
      margin-right: $space-2;
    }

    > a {
      display: block;
      width: 28px;
      height: 28px;
      border: 1px solid;
      border-radius: 100%;
      color: $gray-light;
      line-height: 25px;
      text-align: center;
    }
  }
}

.socialWidget--facebook-share-desktop{
  display: inline-block;
}
.socialWidget--facebook-share-mobile{
  display: none;
}

@media (max-width: $sm) {
  .socialWidget--facebook-share-mobile{
    display: inline-block;
    .socialWidget-label {
      background-color: #1a77f2;
    }
  }
  .socialWidget--facebook-share-desktop{
    display: none;
  }
  .networks {
    > li {
      > a {
        width: 44px;
        height: 44px;
        line-height: 41px;
        font-size: 20px;
      }
    }
  }
}

/**
 * Aspect ratio
 */
.aspectRatio {
  position: relative;
  overflow: hidden;
}

.aspectRatio-fill {
  //padding-bottom: 56.25%; // 16:9
  padding-bottom: 66.66%; // 3:2
  // padding-bottom: (3/4 * 100%); // 4:3
}

.aspectRatio-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/**
 * foto
 */
.fotoHeader {
  margin-bottom: $space-4;
}

.fotoHeader-title,
.fotoHeader-subtitle {
  margin-bottom: 0;
  font-family: $font-1;
}

.fotoHeader-title {
  font-size: 50px;
}

.fotoHeader-subtitle {
  font-size: 25px;
}

.fotoHeader-back,
.fotoHeader-control {
  display: inline-block;
  margin-top: 35px;
}

.fotoPager {
  font-weight: 700;

  &.is-disabled {
    color: $gray-darkest;
    pointer-events: none;
  }
}

.fotoArticle {
}

.fotoArticleTitle {
  margin-top: $space-5;
  margin-bottom: $space-4;
}

.fotoControls {
  padding: 0 ($space-3 - 6px); // - border-width
  margin: 0 0 $space-3;
  overflow-x: auto;
  white-space: nowrap;
}

.fotoControl {
  display: inline-block;
  border: 6px solid transparent;
  border-radius: 6px;
  transition: all 150ms;

  &.is-active {
    border-color: $white;
  }

  &:hover {
    opacity: 0.7;
  }
}

.fotoControl-img {
  width: 120px;
}

.fotoSidebar {
  width: 100%;
}

@media (max-width: $sm) {
  .fotoHeader {
    text-align: center;
  }

  .fotoHeader-back,
  .fotoHeader-control {
    margin-top: 0;
  }

  .fotoHeader-title {
    font-size: 36px;
  }

  .fotoArticleTitle {
    font-size: 42px;
    margin-bottom: $space-4;
  }

  .fotoControls {
    margin-right: -$space-3;
    margin-bottom: $space-2;
    margin-left: -$space-3;
  }

  .fotoControl {
    border-width: 4px;
  }
}

/**
 * google custom search
 */
.gsearch {
  box-sizing: content-box;

  table {
    border-collapse: separate;
  }
}

/**
 * weather
 */
.weather {
  position: relative;
  z-index: 0;
  padding: $space-3 $space-3 0;
  background: #c4d4e1;
  overflow: hidden;
}

.weather-otherCities {
  float: right;
  display: inline-block;
  margin-top: $space-3;
}

.weather-image {
  position: absolute;
  z-index: -1;
  top: -40px;
  left: 60%;
  width: 360px;
  height: 360px;
  margin-left: -180px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.weather-city {
  margin-top: $space-3;
  font-weight: 700;
  font-size: 20px;
}

.weather-temp {
  font-weight: 700;
  font-size: 42px;
  line-height: 1;
}

.weather-status {
  font-size: 18px;
}

.weather-desc {
  width: 90%;
  margin-top: $space-6;
  font-size: 14px;
}

@media (max-width: $sm) {
  .weather-image {
    top: 15px;
    left: 70%;
  }

  .weather-otherCities {
    float: none;
    margin-top: $space-2;
  }

  .weather-city {
    font-size: 40px;
  }

  .weather-temp {
    font-size: 60px;
  }

  .weather-status {
    font-size: 28px;
  }
}

.weather-image--1 {
  background-image: url("../../img/weather/weather1.png");
}

.weather-image--2 {
  background-image: url("../../img/weather/weather2.png");
}

.weather-image--3 {
  background-image: url("../../img/weather/weather3.png");
}

.weather-image--4 {
  background-image: url("../../img/weather/weather4.png");
}

.weather-image--5 {
  background-image: url("../../img/weather/weather5.png");
}

.weather-image--9 {
  background-image: url("../../img/weather/weather9.png");
}

.weather-image--10 {
  background-image: url("../../img/weather/weather10.png");
}

.weather-image--12 {
  background-image: url("../../img/weather/weather12.png");
}

.weather-image--15 {
  background-image: url("../../img/weather/weather15.png");
}

@include retina {
  .weather-image--1 {
    background-image: url("../../img/weather/weather1@2x.png");
  }

  .weather-image--2 {
    background-image: url("../../img/weather/weather2@2x.png");
  }

  .weather-image--3 {
    background-image: url("../../img/weather/weather3@2x.png");
  }

  .weather-image--4 {
    background-image: url("../../img/weather/weather4@2x.png");
  }

  .weather-image--5 {
    background-image: url("../../img/weather/weather5@2x.png");
  }

  .weather-image--9 {
    background-image: url("../../img/weather/weather9@2x.png");
  }

  .weather-image--10 {
    background-image: url("../../img/weather/weather10@2x.png");
  }

  .weather-image--12 {
    background-image: url("../../img/weather/weather12@2x.png");
  }

  .weather-image--15 {
    background-image: url("../../img/weather/weather15@2x.png");
  }
}

/**
 * public figures - politici na fb
 */
.publicFigures {
  position: relative;
  padding: 0;
  min-height: 360px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: $xs) {
  .publicFigures {
    min-height: 420px;
  }
}

/**
 * footer
 */
.footer {
  padding: $space-4 0;
}

.footer-top {
  margin-bottom: $space-3;
}

.footer-contact {
  display: inline-block;
  margin-top: 8px;
}

.footer-networks {
  margin-top: 2px;
}

.footer-menu {
  margin: $space-4 0;
}

.footer-copy {
  font-size: 12px;
  color: $gray;
}

@media (max-width: $sm) {
  .footer-top {
    text-align: center;
  }

  .footer-networks {
    float: none;
    display: inline-block;
    margin: $space-3 0 $space-1;
  }

  .footer-menu {
    > li {
      display: block;

      &:not(:first-child) {
        margin: $space-1 0 0;
      }
    }
  }
}

/**
 * publishing time
 */
.publishingTime {
  display: block;

  .icon {
    margin-top: -0.5ex;
  }
}

/**
Historic Calendar (historicky-kalendar)
*/
.hhistoricky-kalendar {
  .nameday_annotation {
    display: none;
  }

  .historic_calendar_hidden {
    display: none;
  }

  .withthumb {
    padding: 12px 10px;
    border-top: 1px solid #d2d2d2;

    .datethumb {
      width: auto;
      padding: 0;
      margin-right: 10px;
      font-weight: 700;
    }
  }

  .mainarticle,
  .withthumb {
    position: relative;
  }

  strong.datethumb {
    float: left;
    text-align: right;
  }

  p {
    margin: 0 0 0 3.5em;
  }

  .articles {
    margin-bottom: 20px;
  }
}

.cookies {
  position: fixed;
  z-index: 1000;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $space-3;
  background: $gray-lightest;
  border-top: 1px solid $gray-dark;
  text-align: center;

  p {
    margin: 0 0 $space-2;
    line-height: 1.6em;
  }

  .cookies-close {
    padding: $space-2 $space-3;
    border: none;
    border-radius: 3px;
    background: $primary;
    margin: 3px;

    &:disabled {
      background: $gray;
      cursor: not-allowed;
    }

    color: $white;
    appearance: none;
    cursor: pointer;
  }

  .cookies-more {
    margin-left: $space-2;
  }

  .cookieConsentCheckboxWrapper {
    background-color: $gray-dark;
    padding: 8px;
    margin: 10px;
    display: inline-block;
    float: left;
  }

  .cookieConsentBoxWrapper {
    border: 1px black solid;
    display: inline-block;
  }

  .cookieConsentText {
    // max-width: 300px;
    text-align: left;
    padding: 3px;
    float: left;
    margin: 5px;
  }

  // .cookieConsentButtons {
  //     display: flex;
  //     justify-content: center;
  // }
}

.sectionPageTitle {
  font-family: $font-1;
  padding-top: $space-3;

  // padding-bottom: $space-3;
}

.static-page {
  padding-top: 20px;
  padding-bottom: 40px;

  h1 {
    font-size: 56px;
    font-family: "Exo 2";
    text-align: center;
    position: relative;
    &.w-tag{
      display: block;
      margin: 0 auto;
      max-width: 480px;
    }
    .tag{
      position: absolute;
      top: 13px;
      right: 0;
      font-size: 12px;
      display: block;
      color: #2da645;
      text-transform: uppercase;
    }
  }

  .eu-elect-hdl{
    text-align: center;
    color:#0B4EA2;
    width: 100%;
    font-weight: 600;
    padding: 30px 0 20px;
  }
  .eu-elect-table{
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    th{
      font-size: 12px;
      font-weight: 600;
      color: #989898;
      background: #f8f8f8;
      text-align: left;
      padding: 8px;
      border-bottom: 1px solid #adadad;
    }
    td{
      padding: 6px 8px;
      border-bottom: 1px solid #cfcfcf;
    }
  }

  .eu-table-wrapper{
    width: 100%;
    overflow-x: auto;
  }

  &.elections{
    h1{
      color: #0B4EA2;
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 700;
      line-height: 44px;
      text-align: center;
      max-width: 520px;
      margin: 30px auto 45px;
    }
    h2{
      color: #0B4EA2;
      text-transform: uppercase;
      font-size: 25px;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      width: 100%;
      max-width: 800px;
      margin: 40px auto 22px;
    }
    h3{
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
      text-align: center;
      color: #494949;
      b{
        font-weight: 700;
      }
    }
    .counted-graph-wrapper{
      max-width: 480px;
      margin: 30px auto 45px;
    }
    .chart-hdl{
      font-size: 17px;
      font-size: 17px;
      font-weight: 400;
      line-height: 22px;
      text-align: center;
      b{
        font-weight: 700;
      }
    }
  }
}
.b{
  font-weight: bold;
}
.subscribe-xml-text{
  max-width: 780px;
  margin: 50px auto 0;
  ol{
    padding-left: 15px;
    li{
      margin-bottom: 6px;
    }
  }
}
.subscribe-xml-form-wrapper{  
  max-width: 620px;
  margin: 70px auto;  
  text-align: center;

  .form-checkbox-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    label{
      margin-left: 10px;
      font-size: 14px;
      color: #494949;
    }  
  }

  button[type=submit]{
    background: #0B4EA2;
    border-radius: 4px;
    color: #FFF;
    font-size: 18px;
    padding: 10px 70px;
    border: 0;
    display: inline-block;
    margin-top: 20px;
  }
  .form-row{
    display: flex;
    justify-content: center;
    .subscribe-xml-form{
      flex-grow: 1;
      width: 50%;
      &:first-child{
        margin-right: 40px;
      }
    }
  }

  .form-message{
    ul{
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }
  }
  
}

.subscribe-xml-form{  
  h2{
    text-align: left;
    text-transform: none;
    font-weight: 400;
    color: #24568A;
    font-size: 24px;
  }
  input{
    border-radius: 4px;
    background: #f9f9f9;
    border: 1px solid #808080;
    padding: 7px 8px;
    display: block;
    width: 100%;
  }
  label{
    display: block;
    margin-bottom: 3px;
    font-size: 14px;
    color:#1A1A1A;
    text-align: left;
  }
  .form-group{
    margin-bottom: 20px;
  }
}


.newsnowBox {
  width: 100%;
  border: 1px solid $gray-dark;
  border-radius: $space-2;
  background-color: $near-white;
  padding: $space-2;
  text-align: center;
  box-shadow: 20px 20px $white;
  margin-bottom: 50px;
}

.bigBtn {
  // margin: auto 10px auto 10px;
  margin: 10px auto;
  color: $white;
  font-size: 24px;
  background-color: $primary;
  display: inline-block;
  padding: $space-2;
  border: 1px $primary-dark solid;
  border-radius: $space-2;
}

.cofoeDisclaimer {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  color: $gray-dark;
}

@media(max-width: 540px){
  .col-header, .col-body{
    flex-direction: column;
  }
  // .ass-2-cols .col-header .top-col, .ass-4-cols .col-header .top-col {
  //   width: 100%;
  // }
  // .ass-2-cols .col-body .ass-banner-list, .ass-4-cols .col-body .ass-banner-list {
  //   width: 100%;
  // }

  .ass-page-wrapper{
    padding: 20px 15px;
  }
  .row-ass{
    flex-direction: column;
    .col-ass{
      width: 100%;
      &:not(:last-child){
        margin-bottom: 30px;
      }
    }
  }
  .ass-2-cols .row-ass .col-ass, .ass-4-cols .row-ass .col-ass {
    width: 100%;
  }

  .col-search-bar .filter-bar li {
    margin-right: 7px;
    padding-right: 7px;
  }
  .ass-search-results-wrapper .search-results-list > a {
    width: 100%;
  }

  .col-search-bar {
    margin-top: 35px;
    align-items: flex-start;
    flex-direction: column;
    .title{
      margin-bottom: 15px;
    }
    .filter-bar {
      margin-left: 0px;
      padding-left: 0;
      margin-top: 20px;
    }
  }
  .col-search-bar .search-form {
    margin-left: 0px;
    width: 100%;
  }
  .col-search-bar .search-form .input-wrapper input {
    border: 0;
    background: transparent;
    font-size: 16px;
    outline: none;
    flex-grow: 1;
  }

  .ass-page-socials {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 25px 20px;
    flex-direction: column;
  }
  .ass-page-socials ul {
    list-style: none;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-left: 0;
    padding-left: 0;
    li{
      width: calc(50% - 15px);
    }
}

  .col-search-bar .search-form .input-wrapper {
    padding: 9px 15px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .eu-elect-table{
    min-width: 450px;
    th{
      max-width: 90px;
    }
  }

  .form-checkbox-wrapper{
    align-items: flex-start;
    input{
      margin-top: 5px;
    }
  }

  .static-page{
    h1 .tag {
      position: absolute;
      top: 65px;
      right: 29px;
      font-size: 11px;    
    }
    h1.w-tag {
      margin: 0 auto 40px;
      // max-width: 390px;
  }
  }

  .subscribe-xml-form-wrapper{
    .form-row{
      flex-direction: column;
      .subscribe-xml-form {
        width: 100%;
        &:first-child {
          margin-right: 0px;
          margin-bottom: 30px;
        }
      }
    }
  }
  .subscribe-xml-text {
    margin: 30px auto 0;
  }
}


@import "components/liveArticle";
@import "components/liveArticleGeneral";
@import "components/notifications";
