/**
 * header
 */

.headerTop {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;

  width: 100%;
  height: $headerTop-height;

  // padding: 0px 20px;
  border-top: 5px solid $secondary;
  background: $primary;

  z-index: 999;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.55);
  overflow: hidden;

  > .container {
    position: relative;
  }
}

.headerTopMenuBtn {
  height: 38px;

  // padding-top: 5px;
  padding: 0 $space-2;
  border: 1px solid $secondary;
  margin-top: 7px;

  color: $white;
  background: $primary;

  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;

  outline: none;
  transition: all 250ms 250ms;

  will-change: max-width;
  transform: translate3d(0, 0, 0);

  &:hover,
  &:focus {
    background: $primary-light;
  }
}
.headerTopMenuBtn-icon {
  // margin-top: -3px;
  margin-right: $space-2;
}
.headerTopMenuBtn-label {
  position: relative;
  top: 0.1em;
}

// .headerTop-btn .icon {
//  font-size: 1.7em;
//  margin-right: 5px;
// }

.headerTopNav {
  position: relative;
  overflow: hidden;
  // margin: 8px 30px 0px;
  // padding: 8px 0 0;

  transition: all 250ms;
  will-change: transform;
}
.headerTopNav-inner {
  padding: 0 $space-3 0;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;

    width: $space-4;
    pointer-events: none;
  }
  &:before {
    left: 0;
    background: linear-gradient(-90deg, rgba($primary, 0), $primary);
  }
  &:after {
    right: 0;
    background: linear-gradient(90deg, rgba($primary, 0), $primary);
  }
}

.headerTopNav-menu {
  display: inline-block;
  height: ($headerTop-height - 5px);
  padding: 0px;
  // margin: 8px 0px 0px;
  margin: 0;
  line-height: 50px;
  line-height: ($headerTop-height - 5px);
  white-space: nowrap;
}

.headerTopNav-menu li {
  // float: left;
  display: inline-block;
  // padding: 0px 11px;
  margin: 0 $space-2;
  font-weight: 700;
  // font-size: 1em; //1.1em
  font-family: $font-2;
  list-style: none;
}

.headerTopNav-menu li a {
  display: inline-block;
  color: $white;
  // text-decoration: none;
  cursor: pointer;
}

.headerTopLogo {
  position: absolute;
  top: 3px;
  left: 50%;

  visibility: hidden;
  opacity: 0;
  transform: translate3d(-50%, $headerTop-height, 0) scale(0.9);
  // transform: translate3d(-50%, -$headerTop-height, 0);
  transform-origin: center;
  transition: all 250ms;

  will-change: transform;
}

.headerTopWeather {
  height: $headerTop-height - 5px;
  padding: 0 $space-3;
  line-height: $headerTop-height - 6px;

  background: $secondary;

  transition: all 250ms;
  will-change: transform;

  a,
  a:hover {
    text-decoration: none;
  }
}

@media (max-width: $sm) {
  .headerTopWeather {
    padding: 0 $space-1;
  }
}

.headerTopWeather-number {
  margin-right: $space-2;
  color: $white;

  font-weight: 700;
  font-size: 1.1em;
  font-family: $font-2;
}
.headerTopWeather-icon {
  margin-top: -5px;
}

.headerTopBtns {
  // margin-top: 5px;
  margin-top: 8px;
  margin-left: $space-2;
  color: $white;
  // font-size: 1.9em;
  font-size: 24px;
}
.headerTopBtn {
  display: inline-block;
  padding: 0;
  border: none;
  margin-left: $space-2;

  background: none;
  color: $white;
  // vertical-align: middle;
}

/* headerTop on scroll */
.headerTop.is-transformed {
  .headerTopNav,
  .headerTopWeather {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -$headerTop-height, 0);
  }
  .headerTopLogo {
    visibility: visible;
    opacity: 1;
    transform: translate3d(-50%, 0, 0) scale(1);
  }
  .headerTopNav-inner {
    overflow: hidden;
  }
}

/**
 * headerSearch
 */
.headerSearchWrapper {
  position: relative;
}
.headerSearch {
  position: absolute;
  z-index: 10;
  top: 4px;
  right: -10px;
  // left: 0;

  width: 100%;
  width: calc(100% + 10px);
  min-width: 200px;

  // display: none;
  visibility: hidden;
  opacity: 0;

  // transform: translate3d(50%, 0, 0);

  // transform-origin: right;
  transition: 250ms opacity;

  will-change: opacity;
}
.headerSearch-input {
  width: 100%;
  height: 44px;
  padding: 0 50px 0 $space-2;
  border: none;
  border-radius: 3px;

  color: $black;
  font-size: 16px;

  transition: all 250ms;
}
.headerSearch-submit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  padding: 0;
  border: none;
  width: 50px;
  background: none;

  color: $primary;
  font-size: 30px;

  .icon {
    margin-top: -1px;
  }
}
.headerTop.is-searchActive {
  .headerSearch {
    visibility: visible;
    opacity: 1;
    // transform: translate3d(0, 0, 0);
  }
  .headerTopWeather {
    visibility: hidden;
    opacity: 0;
    // transform: translate3d(0, -$headerTop-height, 0);
  }
}

/* Main header */

.headerWrapper {
  display: flex;
  padding: 16px 0px 8px;
  // border-bottom: 2px solid $primary;
  font-size: 14px;
}

.headerLeft,
.headerRight {
  display: flex;
  width: 200px;
  // height: 100%;
}

.headerRight {
  position: relative;
  left: -16px;
}

.headerLeft {
  order: -1;
}

.headerMiddle {
  flex: 1 1 auto;
}

.companyDesc{
  font-family: "Exo 2", sans-serif;
  color: #02578e;
  font-size: 16px;
}

.headerMiddleLogo {
  margin-bottom: $space-2;
  text-align: center;
}

.headerMiddleInfo-item {
  margin-left: 5px;
}

@media (max-width: $md) {
  .headerTopMenuBtn {
    max-width: (26px + 2 * $space-2 + 2px); //icon width + padding + border
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px; //new
  }

  .headerTop.is-transformed .headerTopMenuBtn {
    max-width: 200px;
  }

  .headerWrapper {
    padding-top: 12px;
  }
}
@media (max-width: $sm) {
  .headerTopLogo {
    top: 8px;
  }
}
@media (max-width: $xs) {
  .headerTopMenuBtn-icon {
    margin-right: 0;
  }
  .headerTopMenuBtn-label {
    display: none;
  }

  .headerSearchWrapper {
    position: static;
  }
  .headerSearch {
    left: (44px + $space-3 + $space-2);
    right: $space-3;
    width: auto;
  }
}
