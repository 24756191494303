/**
 * media listing
 */
.mediaListing {
  margin-bottom: -($grid-gutter);
}
.mediaListing-item {
  margin-bottom: $grid-gutter;
}

.mediaListing-item-vertical {
  margin-bottom: $space-3;
  width: 100%;

  .media-title {
    margin: 0;
  }
}

// @include media-max($md) {
//  .col-md-6:nth-of-type(odd) {
//      clear: left;
//  }
// }

/**
 * Media
 */
.media {
  width: 100%;

  .publishingTime {
    margin-top: $space-1;
    color: $gray-darker;
    font-size: 13px;
  }
}
.media-img {
  display: block;

  img {
    width: 100%;
  }
}

.media-content {
  font-size: 13px;

  p:last-of-type {
    margin-bottom: 0;
  }
}

.media-title {
  margin: 10px 0px 0px;
  font-size: 18px;
  line-height: 1.3;
  word-break: break-word;
}
.media-text {
  margin-top: $space-2;
}
.media-footer {
  margin-top: $space-2;
  font-size: 14px;
}

.media--withFloatLabel {
  position: relative;
  margin-top: $space-4;
}
.media-floatLabel {
  position: absolute;
  bottom: 100%;
  margin-bottom: $space-1;
  font-size: 15px;
}

.media--xl {
  .media-title {
    margin-bottom: $space-3;
    // font-size: 42px;
    font-size: 28px;
  }
  .media-content {
    font-size: 16px;
  }
}
.media--lg {
  .media-title {
    font-size: 24px;
  }
  .media-content {
    font-size: 16px;
  }
  // .media-text {
  //     font-size: 14px;
  // }
}
.media--sm {
  .media-title {
    font-size: 18px;
  }
}
.media--horizontal {
  .media-title {
    margin-top: 0;
  }
}

.media--minimal {
  .media-title {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.4;
    margin-top: 20px;

    > a {
      color: $primary-dark;
    }
  }
}

@media (max-width: $md) {
  .media--xl {
    .media-title {
      margin-bottom: $space-3;
      // font-size: 42px;
      font-size: 24px;
    }
  }
  .media--lg {
    .media-title {
      margin-top: 10px;
    }
  }
  .media--sm {
    .media-title {
      font-size: 18px;
    }
  }
  .media--minimal {
    .media-title {
      font-size: 16px;
    }
  }
}

.section--tabletTV {
  .media-title {
    font-family: Montserrat, arial, sans-serif;
  }
}
.media--tabletTV {
  .media-title {
    font-size: 16px;
    font-family: Montserrat, arial, sans-serif;
  }

  &.media--lg {
    .media-title {
      font-size: 24px;
    }

    .media-img {
      position: relative;
      border: 1px solid $blue-light;
      border-radius: 2px;
    }
  }

  .playBtn {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;

    display: table;
    padding: 0px;
    border: none;
    -webkit-appearance: none;

    color: $white;
    background: rgba($primary, 0.8);

    transform: translate(-50%, -50%);
  }
  .playBtn-icon,
  .playBtn-label {
    display: table-cell;
    vertical-align: middle;
  }
  .playBtn-icon {
    padding: 0 $space-2;
    background: $primary;
  }
  .playBtn-label {
    padding: $space-2 $space-3;
    font-family: Montserrat, arial, sans-serif;
    text-transform: uppercase;
    line-height: 1;
  }
}

.section--liveBroadcastsVideos {
  .media-title {
    font-family: Montserrat, arial, sans-serif;
  }
}

.media--liveBroadcastsVideos {
  .media-title {
    font-size: 16px;
    font-family: $font-2;
  }

  .media-img {
    position: relative;
    border: 1px solid $blue-light;
    border-radius: 2px;
  }

  .playBtn {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;

    display: table;
    padding: 0px;
    border: none;
    -webkit-appearance: none;

    color: $white;
    background: rgba($primary, 0.8);

    transform: translate(-50%, -50%);
  }
  .playBtn-icon {
    display: table-cell;
    vertical-align: middle;
  }
  .playBtn-icon {
    padding: 8px 14px;
    background: $primary;
  }
}

.media--vtedy {
  position: relative;
  padding: 10px;
  background: $white-beige;

  .media-category {
    position: absolute;
    z-index: 10;
    top: 20px;
    left: 0;
    padding: $space-1 $space-2 $space-1 $space-4;

    background: $black;
    color: $beige-darker;
    font-family: "Oswald", sans-serif;

    // font-size: 16px;

    font-size: 16px;
  }

  .media-title {
    // margin: $space-3 0px $space-1;
    color: $beige-darkest;
    font-family: "Futura Round";

    a {
      color: inherit;
    }

    // overflow: hidden;
    // font-size: 18px;
  }

  &.media--lg {
    .media-title {
      font-size: 33px;
    }
    .media-category {
      font-size: 20px;
    }
  }
}

.media--foto {
  padding: 10px;
  background: $white;
  color: $black;

  a {
    color: $primary;
  }

  .media-text {
    margin-top: $space-4;
  }

  .media-floatLabel {
    color: $white;
  }
}
.media--xl.media--foto {
  padding: $space-3;
}

@media (max-width: $sm) {
  .media--tabletTV.media--lg{
    .playBtn-label{
      display: none;
    }
    .playBtn-icon {
      padding: 4px 9px;
    }
    .media-title{
      font-size: 15px;
    }
  }
  .section--tabletTV{
    .media-title--tabletTV{
      margin: 0px 0px 0px;
      font-size: 15px;
    }
    aside{
      .media-img{
        max-width: 90px;
      }
    }
  }
  .mediaListing-item{
    &.mobile-row{
      .media{
        display: flex;
        p.media-text{
          display: none;
        }
        .media-title {
          margin: 0px;
        }
        .media-img{
          margin-right: 24px;
          min-width: 88px;
          width: 88px;
        }
      }
    }
  }
  .media{
    &.mobile-row{
      display: flex;
      p.media-text{
        display: none;
      }
      .media-title {
        margin: 0px;
      }
      .media-img{
        margin-right: 24px;
        min-width: 88px;
        width: 88px;
      }
    }
  }
  .media--xl.media--foto {
    padding: 10px;
  }

  .media--liveBroadcastsVideos{
    display: flex;
    .playBtn-icon {
      padding: 4px 9px;
    }
    .media-img{
      min-width: 88px;
      width: 88px;
      max-width: 88px;
      margin-right: 24px;
      object-fit: cover;
      height: 100%;
    }
    .media-title {
      margin: 0px;
      font-size: 18px;
    }
  }
}

.media--fotoDna {
  width: auto;
  min-width: 100%;

  padding-bottom: $space-3;
  margin-right: (-$container-padding);
  margin-left: (-$container-padding);

  background: #fbf9f5;

  .media-content,
  .media-footer {
    padding: 0 $grid-gutter/2;
  }
  .media-title {
    font-size: 22px;
  }
  .media-footer {
    font-size: 1em;
  }
}
