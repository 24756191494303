/**
 * Article
 */

.articleimage:first-of-type {
  display: none;
}
.articleHeader {
  margin-top: $space-5;
}
.articleTitle {
  margin: $space-3 0 $space-5;
  font-family: $font-1;
}

.audio-info-text{
  color: #616161;
  font-size: 15px;
  font-style: italic;
  padding-bottom: 5px;
  display: block;
  margin-top: 30px;
}
.react-player-wrapper{
  > div{
    height: 10px !important;
  }
}
.audio-player-wrapper{
  margin-bottom: 30px;
  padding: 18px 10px 12px;
  background: #f2f2f2;
  position: relative;
  .timeline-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;


    input[type="range"] {
      -webkit-appearance: none;
      width: 100%;
      height: 4px;
      outline: 0;
      background: #c3c3c3;
      display: block;
      transition: background 1s linear;
  }
  
  input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 4px;
      width: 10px;
      border-radius: 3px;
      background: transparent;
      border-radius: 50%;
      border: 0;
      cursor: pointer;
    }
  
  /* All the same stuff for Firefox */
  input[type=range]::-moz-range-thumb {
      height: 4px;
      width: 10px;
      border-radius: 3px;
      background: transparent;
      border: 0;
      border-radius: 50%;
      cursor: pointer;
    }
    
    /* All the same stuff for IE */
    input[type=range]::-ms-thumb {
      height: 4px;
      width: 10px;
      border-radius: 3px;
      background: transparent;
      border-radius: 50%;
      border: 0;
      cursor: pointer;
    }

  }
  .controls-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    > *{
      &:not(:last-child){
        margin-right: 15px;
      }      
    }
    .blank-placeholder{
      width: 40px;
    }
  }
  .playback-rate{
    font-size: 15px;
    color: #adadad;
  }
  .duration-wrapper{
    color: #adadad;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 14px;
  }
  button{
    border: 0;
    background: transparent;
  }
}

.articlePerex {
  font-weight: 700;
  font-size: 26px;
  font-family: $font-1;
}

.articleImage,
.articleimage {
  // margin-bottom: $space-3;

  margin-right: 0;

  img {
    width: 100%;
    display: block;
    margin-bottom: $space-2;
  }
}
.articleImage--left,
.articleImage--right {
  max-width: 50%;
}
.articleImage--left {
  float: left;
  margin-right: $space-3;
}
.articleImage--right {
  float: right;
  margin-left: $space-3;
}

.articleImage-caption {
  font-style: italic;
}

.articleContent {
  font-size: 17px;
}
.articleMain {
  padding-left: $space-5;
  margin-bottom: $space-5;
  line-height: 1.5;

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    font-family: $font-1;
  }

  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4 {
    font-weight: 700;
  }

  h2,
  .h2 {
    // font-size: 36px;
    font-size: 2em;
  }
  h3,
  .h3 {
    // font-size: 28px;
    font-size: 1.555555556em;
  }
  h4,
  .h4 {
    // font-size: 21px;
    font-size: 1.166666667em;
  }

  p,
  ul,
  ol {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .slider {
    width: auto;
  }

  .articleImage,
  .articleimage,
  .slider,
  .twitter-tweet,
  .articlepanel,
  .articlePanel {
    margin-left: (-$space-5);
  }

  div {
    max-width: 100%;
  }
  .articlepanel,
  .articlePanel {
    max-width: calc(100% + #{$space-5});
  }
  .articleImage,
  .articleimage {
    max-width: none;
  }

  .articlepanel,
  .articlePanel {
    background: $near-white;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  iframe {
    max-width: calc(100%);
  }
}

.articleMeta {
  margin-bottom: $space-5;
  color: $gray;
  font-size: 14px;
}
.articleMeta-person {
  float: left;

  // &:not(:last-child) {
  //     &:after {
  //         content: '';
  //         margin: 0 $space-3;
  //         border-right: 1px solid $gray;
  //     }
  // }

  /**
	 * ...a Karči povedal: "urob to pls cez CSS"
	 */
  // &:last-child {
  // 	display: none;
  // }
}

@media (max-width: $lg) {
  .articleTitle {
    font-size: 46px;
  }
  .articleContent {
  }
}
@media (max-width: $md) {
  .articleTitle {
    font-size: 28px;
  }
  .articlePerex {
    font-size: 21px;
  }
  .articleMain {
    padding-left: 0;

    .articleImage,
    .articleimage,
    .slider,
    .twitter-tweet,
    .articlepanel,
    .articlePanel,
    iframe {
      margin-left: 0;
    }
    .articlepanel,
    .articlePanel,
    iframe {
      max-width: 100%;
    }
  }  
}
@media (max-width: 490px) {
  .audio-player-wrapper .controls-wrapper > * {
    &:not(:last-child){
      margin-right: 7px;
    }
  }
  .audio-player-wrapper .duration-wrapper {
    right: 10px;
    font-size: 12px;
  }
}
@media (max-width: $sm) {
  
}
@media (max-width: $xs) {
  .articleImage {
    float: none;

    margin-right: -($container-padding);
    margin-left: -($container-padding);

    // img {
    // 	margin-right: -($container-padding);
    // 	margin-left: -($container-padding);
    // }
  }

  // .articleMain {
  // 	.articleImage,
  // 	.articleimage,
  // 	.slider,
  // 	.twitter-tweet {
  // 		margin-right: -($container-padding);
  // 		margin-left: -($container-padding);
  // 	}
  // }
}

/*
 * article actions
 */
.articleActions {
  margin: $space-5 0;
}
.articleActions-block {
  display: inline-block;
  vertical-align: middle;

  &:not(:last-child) {
    margin-right: $space-4;
  }
}

/* report bugs */

#articleReportBug > textarea {
  width: 100%;
  min-height: 230px;
  margin: 0 0 10px;
}

#modalHeading {
  font-size: 28px;
}

.btn-close-modal {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.articleMain td {
  border: $gray-darkest 1px solid;
  padding: 2px 4px 1px 4px;
}

.articleImageTop-caption {
  position: absolute;
  bottom: 0;
  top: 0;
  opacity: 0;
  margin-bottom: $space-2;
  //visibility: hidden;
  width: 100%;
  &:hover {
    //visibility: visible;
    opacity: 1;
  }
  figcaption {
    position: absolute;
    color: $white;
    background: rgba($gray-darkest, 0.8);
    bottom: 0;
    max-width: 90%;
    padding: $space-1;
  }
  a {
    color: $white;
    text-decoration: underline;
  }
}

/** SUPER DIRTY HACK HOTFIX **/
.articleMain .rightNow {
  div {
    max-width: none;
  }
  .owl-item {
    display: inline-block;
  }
  .owl-prev.disabled,
  .owl-next.disabled {
    visibility: hidden;
  }
  &.slider {
    padding-left: 0;
  }
}

.articleMain.notFinished > div:last-child {
  position: relative;
}

.articleMain.notFinished > div:last-child::before {
  pointer-events: none;
  content: "";
  position: absolute;
  display: block;
  height: 10em;
  max-height: 100%;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff);
}

.articleSplitButtonWrapper {
  width: 100%;
  height: 4em;
}

.articleSplitButtonWrapper button {
  margin: 1em auto;
  display: block;

  padding: 10px;
  border-radius: 7px;
  font-size: medium;
}
