/**
 * module
 */
.module {
  min-width: 100%;
  margin-bottom: $space-5;
  // font-size: 14px;

  .module {
    margin-bottom: $space-2;
  }
}
// title outside of header - typically in nested modules
.module-title {
  a {
    color: inherit;
  }
}

.moduleHeader {
  padding-bottom: $space-2;
  // margin-bottom: $space-3;
}

.moduleHeader-title {
  display: inline-block;
  margin: 0 $space-4 0 0;
  font-family: $font-1;

  &.sm-r-mg {
    margin: 0 $space-2 $space-1 0;
  }
  img {
    display: block;
    margin: 0 auto;
  }

  a {
    color: inherit;

    // &:hover {
    //     text-decoration: none;
    // }
  }
}

.moduleHeader-menu {
  display: inline-block;
  vertical-align: bottom;
  // margin-left: $space-4;
}

.moduleHeader-tabs {
  margin: 2px 0 0;
  list-style: none;

  li {
    float: left;
    display: inline-block;
    margin: 0px 2px;
    line-height: 1;

    a {
      display: block;
      padding: 5px 4px;
      border: 1px solid transparent;

      // color: $primary;
      font-weight: 700;
      text-decoration: none;
    }

    &.is-active {
      color: $gray-darkest;

      a {
        border-color: currentColor;
        color: inherit;
      }
    }
  }
}

.moduleHeader--light {
  .moduleHeader-tabs {
    li.is-active {
      color: $white;
    }
    li {
      color: $gray-dark;

      a {
        color: inherit;
      }
    }
  }
}
.moduleHeader--sidebar {
  display: flex;
  .moduleHeader-title {
    flex: 1 1 auto;
  }
  .moduleHeader-tabs {
    flex: 0 0 auto;
  }
}

.moduleMain {
  padding-top: $space-2;
}

.moduleMain--borderTop {
  position: relative;
  padding-top: $space-3;

  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;

    display: block;
    border-bottom: 2px solid $primary;
    margin-right: -($container-padding);
    margin-left: -($container-padding);
  }
}

.moduleMain--tabletTV {
  padding-top: $space-1;
}
.moduleMain--rightNow {
  padding-top: 0;
  .moduleFooter {
    margin-top: 5px;
  }
}

.moduleFooter {
  padding-top: $space-2;
  margin-top: $space-3;
}
.moduleFooter--borderTop {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 0;
    left: 0;

    display: block;
    border-bottom: 1px solid $gray-lighter;
    margin-right: -($container-padding);
    margin-left: -($container-padding);
  }
}

.top-articles-grid-container {
  display: grid;
  grid-template-columns: 1.6fr 0.8fr 0.6fr;
  grid-template-rows: 0.6fr 1.4fr 0fr;
  gap: 24px 33px;
  padding: 20px;
  grid-template-areas:
    "top-article-top right-now-list right-col-grid"
    "top-articles-list live-broadcasts-list right-col-grid"
    "top-articles-list live-broadcasts-list right-col-grid";
}
.right-col-grid {
  grid-area: right-col-grid;
}
.top-articles-list {
  grid-area: top-articles-list;
}
.live-broadcasts-list {
  grid-area: live-broadcasts-list;
  .liveBroadcastsVideos {
    padding-top: 0;
    margin-top: -7px;
  }
}
.top-article-top {
  grid-area: top-article-top;
  .mediaListing-item.col.col-12 {
    padding: 0;
  }
  .media--lg .media-img {
    padding-bottom: 8px;
  }
}
.right-now-list {
  grid-area: right-now-list;
}

.moduleMain--liveBroadcastsVideos {
  .mediaListing-item {
    margin-bottom: $space-5;
  }
}

.module--tabletTV,
.module--liveBroadcastsVideos {
  .moduleHeader-menu {
    padding: 0;

    > li {
      font-family: Montserrat, arial, sans-serif;
      font-weight: 700;
      font-size: 15px;
      // padding: $space-1 0;

      text-transform: uppercase;
      display: inline-block;
    }

    .play {
      display: block;
      background: $blue;
      padding: $space-1 $space-3;
      margin: 4px; // because box-shadow
      border-radius: 3px;
      box-shadow: 0 0 0 4px $blue-light;

      color: $white;

      .icon {
        display: inline-block;
        margin-top: -2px;
        margin-right: $space-2;
        margin-left: -4px;
      }
    }
  }
}

.boxPriamePrenosyNRSR {
  margin-top: 20px;
  color: white;
  background-color: #005a8c;
  display: inline-block;
  padding: 12px 20px 21px;
  &:hover {
    text-decoration: none;
  }
  p {
    font-size: 31px;
    line-height: 37px;
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .btn-more-info {
    border: 1px solid white;
    text-transform: uppercase;
    font-size: 15px;
    padding: 5px 12px;
  }
}

.module--vtedy {
  width: 100%;
  margin: $space-4 0px;

  .moduleHeader-title {
    img {
      margin-bottom: -8px;
      transition: all 150ms;
    }

    a:hover img {
      opacity: 0.7;
    }
  }

  .moduleHeader-menu {
    margin-top: 12px;
    vertical-align: 8px;

    li {
      margin-right: $space-3;
      margin-left: $space-3;

      font-weight: 400;
      font-size: 18px;
      font-family: "Oswald", sans-serif;
      a {
        color: $black;
      }
    }
  }

  .playBtn {
  }
}

.module--foto {
  // .moduleHeader-title {
  //     font-size: 28px;
  // }
  .moduleFooter {
    padding-top: 0;
  }
}
.module--fotoMain {
  // padding-right: 50px;
  // padding-left: 50px;
}

@media (max-width: $sm) {
  .boxPriamePrenosyNRSR {
    margin-top: 20px;
    padding: 12px 30px 21px;
    text-align: center;
    p {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 18px;
    }
  }
  .top-articles-grid-container {
    display: block;
    padding: 20px 16px 20px;
  }
  .module .module {
    margin-bottom: 20px;
  }
  .module.module--tabletTV {
    margin-bottom: 0px;
  }
  .module-iframe--preferencie,
  .bannerBase {
    display: none;
  }
  .moduleHeader {
    text-align: center;
  }
  .moduleHeader-title,
  .moduleHeader-menu {
    display: block;
  }
  .moduleHeader-title {
    margin: 0;
  }
  .moduleHeader-menu {
    margin-top: $space-3;
  }
  .moduleHeader-tabs {
    float: none;
    margin-top: $space-2;

    li {
      float: none;

      a {
        padding: 8px;
        font-size: 18px;
      }
    }
  }

  .moduleHeader--sidebar {
    display: block;
  }
}
