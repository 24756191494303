/* LIVE */
section.liveprenos p {
  margin: 0;
}

section.liveprenos {
  font-family: Arial;
  font-size: 16px;
  line-height: 23px;
  padding: 20px 0 0 0;
  width: 90%;
  margin: 0 auto;
  background: #fff;
}

section.liveprenos h1 {
  font: bold 42px/50px HeadingFont, Arial, sans-serif;
  font-weight: bold;
  color: #222;
}

section.liveprenos h2 {
  font-size: 25px;
  line-height: 25px;
  height: 25px;
  display: block;
  clear: both;
  font-weight: 200;
  color: #2f2f2f;
  margin: 0 0 0 10px;
  padding: 25px 0 10px 0;
}

section.liveprenos h3 {
  font-size: 24px;
  line-height: 24px;
  height: 24px;
  color: #4097b5;
  font-weight: bold;
  margin: 10px 0 5px 0;
}

p.teaser {
  font: 20px/28px HeadingFont, sans-serif;
  color: #222;
  margin: 15px 10px 15px 18px;
  font-weight: normal;
}

section.liveprenos div.match {
  width: 100%;
  height: 314px;
}

section.liveprenos div.match.hockey {
  width: 100%;
  height: 314px;
  /*background: url(http://www.teraz.sk/templates/teraz/assets/images/bg-live.png) no-repeat top center;*/
  position: relative;
}

section.liveprenos div.teams {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 315px;
  /*background: url(http://www.teraz.sk/templates/teraz/assets/images/bg-team_match.png) no-repeat top center;*/
}

section.liveprenos span.score {
  width: 100%;
  display: block;
  text-align: center;
  position: absolute;
  top: 60px;
  font-size: 100px;
  font-weight: bold;
  height: 120px;
  line-height: 120px;
}

section.liveprenos span.score_thirds {
  width: 100%;
  display: block;
  text-align: center;
  position: absolute;
  top: 200px;
  font-size: 15px;
  font-weight: bold;
  height: 15px;
  line-height: 15px;
}

@media screen and (max-width: 800px) {
  section.liveprenos span.score {
    font-size: 84px;
  }

  section.liveprenos span.score_thirds {
    top: 180px;
  }
}

@media screen and (max-width: 600px) {
  section.liveprenos span.score {
    font-size: 70px;
  }
  section.liveprenos span.score_thirds {
    top: 160px;
  }
}

@media screen and (max-width: 480px) {
  section.liveprenos span.score {
    font-size: 50px;
  }

  section.liveprenos span.score_thirds {
    top: 140px;
  }
}

section.liveprenos div.players {
  background: #edf1ff
    url(http://www.teraz.sk/templates/teraz/assets/images/bg-shadow.png)
    no-repeat top center;
  font-size: 11px;
  line-height: 16px;
  font-style: italic;
  margin-bottom: 0;
}

section.liveprenos p.rozhodcovia {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/bg-rozhodcovia.png)
    repeat-x center center;
  height: 26px;
  line-height: 26px;
  font-style: italic;
  margin-bottom: 20px;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
}

section.liveprenos div.players_leftteam {
  float: left;
  width: 45%;
  margin-left: 2%;
  padding: 15px 0;
}

section.liveprenos div.players_rightteam {
  float: right;
  width: 45%;
  margin-right: 2%;
  padding: 15px 0;
}

section.liveprenos .clear {
  display: block;
  clear: both;
}

section.liveprenos div.stream p {
  border-bottom: 1px solid #dcdcdc;
  padding: 5px 50px 5px 105px;
  font-size: 13px;
  line-height: 18px;
  position: relative;
  color: #424242;
}

section.liveprenos .leftscores {
  width: 40%;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  bottom: 10px;
  left: 0;
}

section.liveprenos .rightscores {
  width: 40%;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  bottom: 10px;
  right: 0;
  text-align: right;
}

section.liveprenos .teams_leftteam {
  position: absolute !important;
  top: 70px !important;
  left: 0 !important;
  width: 150px !important;
  text-align: center !important;
}

section.liveprenos .teams_rightteam {
  position: absolute !important;
  top: 70px !important;
  right: 0 !important;
  width: 150px !important;
  text-align: center !important;
}

@media screen and (max-width: 700px) {
  section.liveprenos .teams_leftteam {
    width: 120px !important;
  }

  section.liveprenos .teams_rightteam {
    width: 120px !important;
  }
}

@media screen and (max-width: 480px) {
  section.liveprenos .teams_leftteam {
    width: 100px !important;
  }

  section.liveprenos .teams_rightteam {
    width: 100px !important;
  }
}

section.liveprenos .teams_leftteam img,
.teams_rightteam img {
  margin: 0 auto;
  display: block;
  border: 1px solid #ddd;
}

section.liveprenos .teams_leftteam span {
  font-weight: bold;
  font-style: italic;
}

section.liveprenos .teams_rightteam span {
  font-weight: bold;
  font-style: italic;
}

section.liveprenos span.icon {
  display: block;
  position: absolute;
  left: 70px;
  top: 5px;
  width: 23px;
  height: 20px;
  z-index: 1000;
}

section.liveprenos span.icon.puk {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/puk.png)
    no-repeat center center;
}

section.liveprenos span.icon.vykricnik {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/vykricnik.png)
    no-repeat center center;
}

section.liveprenos span.icon.striedanie {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/striedanie.png)
    no-repeat center center;
}

section.liveprenos span.icon.cervena_karta {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/cervena-karta.png)
    no-repeat center center;
}

section.liveprenos span.icon.zlta_karta {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/zlta-karta.png)
    no-repeat center center;
}

section.liveprenos span.icon.cielova_vlajka {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/cielova-vlajka.png)
    no-repeat center center;
}

section.liveprenos span.icon.tenisova_lopta {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/tenisova-lopta.png)
    no-repeat center center;
}

section.liveprenos .event_info {
  display: block;
  position: absolute;
  left: 50px;
  top: 28px;
  font-size: 13px;
  font-family: MyriadWebPro;
  height: 13px;
  line-height: 16px;
  font-style: italic;
  font-weight: bold;
}

section.liveprenos .live {
  background: url(http://www.teraz.sk/templates/teraz/assets/images/icon-live.png)
    no-repeat left center;
  display: block;
  position: absolute;
  right: 50px;
  padding: 0 0 0 18px;
  top: 28px;
  font-size: 13px;
  font-family: MyriadWebPro;
  height: 13px;
  line-height: 16px;
  font-style: italic;
  font-weight: bold;
}

section.liveprenos span.time {
  position: absolute;
  top: 5px;
  left: 25px;
  font-size: 11px;
  font-weight: bold;
  color: #000;
}

section.liveprenos .articleimage,
section.liveprenos .articleimage:first-of-type {
  display: block;
}
